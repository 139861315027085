import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Montserrat:400,400i,500,700,800,800i'],
  },
  fontactive() {
    // Cannot use `new window.Event("fontactive")` because of Internet Explorer.
    const event = document.createEvent('Event');
    event.initEvent('fontactive', /* bubbles */ false, /* cancelable */ false);
    document.dispatchEvent(event);
  },
  fontinactive(familyName, fvd) {
    window.console.warn('WebFont.load() failed to load font:', {
      familyName,
      fvd,
    });
  },
});
